// AppointmentList.js
import React, { useState } from 'react';
import DataGrid from 'react-data-grid'; // Importing React Data Grid component
import styles from './AppointmentList.module.css'; // Scoped styling for Appointment List

const AppointmentList = () => {
  // State to manage appointment data
  const [appointments, setAppointments] = useState([
    {
      id: 1,
      patientName: 'Harish R',
      doctor: 'Dr. Suman',
      date: '2024-09-15',
      time: '10:00 AM',
      reason: 'General Checkup',
    },
    {
      id: 2,
      patientName: 'Jayalakshmi',
      doctor: 'Dr. Suraj',
      date: '2024-09-16',
      time: '11:30 AM',
      reason: 'Follow-up',
    },
  ]);

  // Columns configuration for the data grid
  const columns = [
    { key: 'id', name: 'ID' },
    { key: 'patientName', name: 'Patient Name' },
    { key: 'doctor', name: 'Doctor' },
    { key: 'date', name: 'Date' },
    { key: 'time', name: 'Time' },
    { key: 'reason', name: 'Reason for Visit' },
    {
      key: 'actions',
      name: 'Actions',
      formatter: ({ row }) => (
        <div>
          <button
            className={styles.actionButton}
            onClick={() => handleEdit(row.id)}
          >
            Edit
          </button>
          <button
            className={styles.actionButton}
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Handle Edit button click
  const handleEdit = (appointmentId) => {
    console.log('Edit appointment with ID:', appointmentId);
    // Implement edit logic here (e.g., open modal, redirect to edit page)
  };

  // Handle Delete button click
  const handleDelete = (appointmentId) => {
    console.log('Delete appointment with ID:', appointmentId);
    const updatedAppointments = appointments.filter(
      (appointment) => appointment.id !== appointmentId
    );
    setAppointments(updatedAppointments); // Update state to remove appointment
  };

  return (
    <div className={styles.appointmentListContainer}>
      <h2 className={styles.title}>Appointment List</h2>
      <DataGrid
        columns={columns}
        rows={appointments}
        className={styles.dataGrid}
        rowHeight={35}
        headerRowHeight={40}
      />
    </div>
  );
};

export default AppointmentList;
