// App.js
import React from 'react';
import RegistrationForm from '../components/RegistrationForm'
import DataGrid from '../components/Datagrid'
import Layout from '../components/Layout';
import style from './RegistrationPage.module.css'

function RegistrationPage() {
  const data = {
    columns: [
      { key: 'id', name: 'ID' },
      { key: 'name', name: 'Name' },
      { key: 'age', name: 'Age' },
      // Add more columns as needed
    ],
    rows: [
      { id: 1, name: 'N Bharadwaj', age: 32 },
      { id: 2, name: 'Sushanth Sharma', age: 28 },
      { id: 3, name: 'Lakshmi', age: 45 },
      // Add more rows as needed
    ],
  };

  const data2 = {
    columns: [
      { key: 'id', name: 'ID' },
      { key: 'name', name: 'Name' },
      { key: 'age', name: 'Age' },
      // Add more columns as needed
    ],
    rows: [
      { id: 1, name: 'Ravi Kumar', age: 42 },
      { id: 2, name: 'Jithin Varma', age: 68 },
      { id: 3, name: 'Amithabh Bachchan', age: 75 },
      // Add more rows as needed
    ],
  };

  return (
    <Layout>  
      <div className={style.leftSection}>
          <RegistrationForm />
      </div>    
      <div className={style.rightSection}>
        <DataGrid heading = {"Today's Patient List"} data={data} />
        <DataGrid heading = {"Tomorrows's Appointment List"} data={data2} />
      </div>
    </Layout>
  );
}

export default RegistrationPage;
