// App.js
import React from 'react';
import Layout from '../components/Layout'
import { ConsultantProvider } from '../context/ConsultantContext';
import style from './AppointmentPage.module.css'
import AppointmentForm from '../components/AppointmentForm';
import AppointmentList from '../components/AppointmentList';

function ConsultantPage() {
  return (    
    <Layout>      
          <div className={style.leftSection}>
              <AppointmentForm />
          </div>    
          <div className={style.rightSection}>            
             <AppointmentList />
          </div>            
    </Layout>
  );
}

export default ConsultantPage;
