// App.js
import {useState} from 'react';
import BillingForm from '../components/BillingForm';
import Layout from '../components/Layout';
import BillPreview from '../components/BillPreview';
import style from './BillingPage.module.css'

function BillingPage() {
    // State to control the display of the bill preview
    const [displayBill, setDisplayBill] = useState(false);

    // Handler for Submit button click in the BillingForm
    const handleSubmit = () => {      
      setDisplayBill(true); // Set the state to true to display the bill
    };

  return (
    <div>
      <Layout>      
          <div className={style.leftSection}>
              <BillingForm handleSubmit={handleSubmit}/>
          </div>    
          <div className={style.rightSection}>            
              <BillPreview displayBill={displayBill}  />
          </div>            
      </Layout>
    </div>
  );
}

export default BillingPage;
