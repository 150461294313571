import React from 'react';
import styles from './BillPreview.module.css';
import bill from '../assets/billinvoice.png';

const BillPreview = ({ displayBill }) => {
  // Function to handle print action
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className={styles.billPreviewContainer}>
      {/* Header */}
      <h2 className={styles.header}>Bill Preview</h2>
      
      {/* Print Button */}
      <button className={styles.printButton} onClick={handlePrint}>
        Print
      </button>
      
      {/* Conditional Rendering of Bill Image */}
      {displayBill ? (
        <div className={styles.billImageContainer}>
          <img src={bill} alt="Bill Preview" className={styles.billImage} />
        </div>
      ) : (
        <div className={styles.noBillMessage}>No bill to display. Please submit the form to view the bill.</div>
      )}
    </div>
  );
};

export default BillPreview;
