import PatientSearch from "../components/PatientSearch";
import Layout from "../components/Layout";

const PatientSearchPage = () => {
  return (
    <Layout>
        <PatientSearch />
    </Layout>
  )
};

export default PatientSearchPage