// Layout.js
import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import styles from './Layout.module.css';

function Layout({ children }) {
  return (
    <div className={styles.layout}>
      <Navbar /> {/* Navbar will be fixed at the top */}
      <div className={styles.mainContainer}>
        <Sidebar /> {/* Sidebar will be fixed on the left */}
        <div className={styles.content}>          
          {children}
        </div> {/* Content area will scroll */}
      </div>
    </div>
  );
}

export default Layout;
