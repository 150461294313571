import React from "react";
import Dashboard from "../components/Dashboard";    
import Layout from '../components/Layout'

const DashboardPage = () =>{
    return (
        <Layout>
            <Dashboard />
        </Layout>      
    )
}

export default DashboardPage;