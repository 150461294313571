// AppointmentForm.js
import React, { useState } from 'react';
import Calendar from 'react-calendar'; 
import 'react-calendar/dist/Calendar.css'; 
import styles from './AppointmentForm.module.css'; // Ensure module.css for scoped styling

const AppointmentForm = () => {
  // State management for form fields
  const [formData, setFormData] = useState({
    patientId: '',
    patientName: '',
    doctor: '',
    timeSlot: '',
    date: new Date(), // Initialize date with current date
    reason: '',
    remarks: '',
  });

  // Sample function to simulate fetching patient name based on patient ID
  const fetchPatientName = (patientId) => {
    // Mock patient data
    const patientData = {
      P001: 'John Doe',
      P002: 'Jane Smith',
      P003: 'Alice Johnson',
    };
    return patientData[patientId] || 'Patient Not Found';
  };

  // Handle input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle date change from Calendar
  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission (console.log here for demonstration)
    console.log('Form Submitted:', formData);
    // Reset form after submission
    setFormData({
      patientId: '',
      patientName: '',
      doctor: '',
      timeSlot: '',
      date: new Date(),
      reason: '',
      remarks: '',
    });
  };

  // Clear form fields
  const handleClearForm = () => {
    setFormData({
      patientId: '',
      patientName: '',
      doctor: '',
      timeSlot: '',
      date: new Date(),
      reason: '',
      remarks: '',
    });
  };

  // Handle Search button click to fetch patient name
  const handleSearch = () => {
    const patientName = fetchPatientName(formData.patientId);
    setFormData({ ...formData, patientName });
  };

  return (
    <form className={styles.appointmentForm} onSubmit={handleFormSubmit}>
     <h2 className={styles.title}>Appointment Form</h2>
      {/* Patient ID Input and Search Button */}
      <div className={styles.patientSearch}>
        <label htmlFor="patientId">Patient ID:</label>
        <input
          type="text"
          name="patientId"
          value={formData.patientId}
          onChange={handleInputChange}
          placeholder="Enter Patient ID"
        />
        <button className={styles.formButton} type="button" onClick={handleSearch}>
          Search
        </button>
      </div>

      {/* Patient Name Display */}
      {formData.patientName && (
        <div className={styles.patientName}>
          <label>Patient Name: {formData.patientName}</label>
        </div>
      )}

      {/* Calendar for Appointment Date */}
      <div className = {styles.appCalender}>
        <label>Appointment Date:</label>
        <Calendar onChange={handleDateChange} value={formData.date} />
      </div>

      
      <div className={styles.inlineContainer}>
        <div className={styles.inlineField}>
            <label htmlFor="doctor">Doctor:</label>
            <input
            type="text"
            name="doctor"
            value={formData.doctor}
            onChange={handleInputChange}
            placeholder="Enter doctor's name"
            />
        </div>

        <div className={styles.inlineField}>
            <label htmlFor="timeSlot">Time Slot:</label>
            <input
            type="time"
            name="timeSlot"
            value={formData.timeSlot}
            onChange={handleInputChange}
            />
        </div>
        </div>

      {/* Reason for Visit */}
      <label htmlFor="reason">Reason for Visit:</label>
      <input
        type="text"
        name="reason"
        value={formData.reason}
        onChange={handleInputChange}
        placeholder="Enter reason for visit"
      />

      {/* Remarks */}
      <label htmlFor="remarks">Remarks:</label>
      <textarea
        name="remarks"
        value={formData.remarks}
        onChange={handleInputChange}
        placeholder="Any additional remarks"
      ></textarea>

      {/* Submit and Clear Buttons */}
      <div className={styles.buttonContainer}>
        <button className = {styles.formButton} type="submit">Submit</button>
        <button className = {styles.formButton} type="button" onClick={handleClearForm}>Clear</button>
      </div>
    </form>
  );
};

export default AppointmentForm;
