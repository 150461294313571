import Layout from '../components/Layout'
const Help = () =>{
    return(
        <Layout>
            <h1>Help</h1>
            <p>Coming soon</p>
        </Layout>
    )
}

export default Help;