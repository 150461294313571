import React from 'react';
import DatePicker from 'react-datepicker'; // Import DatePicker for date selection
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker styles
import styles from './RegistrationForm.module.css'; // Import the CSS Module

function RegistrationForm() {
  return (
    <div className={styles.registrationContainer}>
      <h2 className={styles.mainHeading}>Patient Registration Form</h2>

      <div className={styles.formHeaderContainer}>
        <h2 className={styles.formHeading}>Demographic details</h2>
        <hr className={styles.sectionLine} />
      </div>

      {/* Demographic Details Section */}
      <section className={styles.formSection}>        
        <div className={styles.formGroup}>
          {/* Patient ID and New Button */}          
          <div className={styles.inlineGroup}>
            <label htmlFor="firstName" className={styles.formLabel}>First Name</label>
            <input type="text" id="firstName" placeholder="First Name" className={styles.formInput} />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inlineGroup}>
            <label htmlFor="lastName" className={styles.formLabel}>Last Name</label>
            <input type="text" id="lastName" placeholder="Last Name" className={styles.formInput} />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="dob" className={styles.formLabel}>Date of Birth</label>
          <DatePicker className={styles.formInput} placeholderText="Select Date" />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="gender" className={styles.formLabel}>Gender</label>
          <select id="gender" className={styles.formInput}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.formLabel}>Email</label>
          <input type="email" id="email" placeholder="Enter your email" className={styles.formInput} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNumber" className={styles.formLabel}>Phone Number</label>
          <input type="text" id="phoneNumber" placeholder="Enter your phone number" className={styles.formInput} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="address" className={styles.formLabel}>Address</label>
          <input type="text" id="address" placeholder="Enter your address" className={styles.formInput} />
        </div>
        <div className={styles.formGroup}>          
            <label htmlFor="city" className={styles.formLabel}>City</label>
            <input type="text" id="city" placeholder="Enter your city" className={styles.formInput} />          
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="pincode" className={styles.formLabel}>Pincode</label>
            <input type="text" id="pincode" placeholder="Enter your pincode" className={styles.formInput} />          
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="bloodGroup" className={styles.formLabel}>Blood Group</label>
          <select id="bloodGroup" className={styles.formInput}>
            <option value="">Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="referralDoctor" className={styles.formLabel}>Referral Doctor</label>
          <select id="referralDoctor" className={styles.formInput}>
            <option value="">Select Referral Doctor</option>
            <option value="Dr. Anil Kumar">Dr. Anil Kumar</option>
            <option value="Dr. Priya Sharma">Dr. Priya Sharma</option>
            <option value="Dr. Vikram Patel">Dr. Vikram Patel</option>
            <option value="Dr. Nidhi Gupta">Dr. Nidhi Gupta</option>
            <option value="Dr. Rajesh Mehta">Dr. Rajesh Mehta</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="referralClinic" className={styles.formLabel}>Referral Clinic</label>
          <select id="referralClinic" className={styles.formInput}>
            <option value="">Select Referral Clinic</option>
            <option value="Apollo Clinic">Apollo Clinic</option>
            <option value="Fortis Health Center">Fortis Health Center</option>
            <option value="Max Care Clinic">Max Care Clinic</option>
            <option value="Manipal Clinic">Manipal Clinic</option>
            <option value="Narayana Clinic">Narayana Clinic</option>
          </select>
        </div>
      </section>


      <div className={styles.formHeaderContainer}>
        <h3 className={styles.sectionHeading}>Emergency Contact</h3>
        <hr className={styles.sectionLine} />
      </div>
      {/* Emergency Contact Section */}
      <section className={styles.formSection}>        
        <div className={styles.formGroup}>
          <div className={styles.inlineGroup}>
            <label htmlFor="emergencyContactName" className={styles.formLabel}>Emergency Contact Name</label>
            <input type="text" id="emergencyContactName" placeholder="Contact Name" className={styles.formInput} />
          </div>
          <div className={styles.inlineGroup}>
            <label htmlFor="relationship" className={styles.formLabel}>Relationship to Patient</label>
            <select id="relationship" className={styles.formInput}>
              <option>Parent</option>
              <option>Spouse</option>
              <option>Sibling</option>
              {/* More options */}
            </select>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="emergencyContactNumber" className={styles.formLabel}>Emergency Contact Number</label>
          <input type="text" id="emergencyContactNumber" placeholder="Emergency Contact Number" className={styles.formInput} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="familyDoctorName" className={styles.formLabel}>Family Doctor Name</label>
          <input type="text" id="familyDoctorName" placeholder="Family Doctor Name" className={styles.formInput} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="familyDoctorContact" className={styles.formLabel}>Family Doctor Contact Number</label>
          <input type="text" id="familyDoctorContact" placeholder="Family Doctor Contact Number" className={styles.formInput} />
        </div>
      </section>

      {/* Health History Section */}
      <div className={styles.formHeaderContainer}>
        <h3 className={styles.sectionHeading}>Health History</h3>
        <hr className={styles.sectionLine} />
      </div>
      <section className={styles.formSection}>        
        <div className={styles.formGroup}>
          <label htmlFor="allergies" className={styles.formLabel}>Known Allergies</label>
          <textarea id="allergies" placeholder="Known Allergies" className={styles.formInput}></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="medicalConditions" className={styles.formLabel}>Existing Medical Conditions</label>
          <textarea id="medicalConditions" placeholder="Existing Medical Conditions" className={styles.formInput}></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="medications" className={styles.formLabel}>Current Medications</label>
          <textarea id="medications" placeholder="Current Medications" className={styles.formInput}></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="surgeries" className={styles.formLabel}>Previous Surgeries</label>
          <textarea id="surgeries" placeholder="Previous Surgeries" className={styles.formInput}></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="vaccineDose" className={styles.formLabel}>Vaccine Dose</label>
          <select id="vaccineDose" className={styles.formInput}>
            <option value="">Select Dose</option>
            <option value="Dose1">Dose1</option>
            <option value="Dose2">Dose2</option>
            <option value="Dose3">Dose3</option>
            <option value="Dose4">Dose4</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="vaccineType" className={styles.formLabel}>Vaccine Type</label>
          <select id="vaccineType" className={styles.formInput}>
            <option value="">Select Vaccine Type</option>
            <option value="Covishield">Covishield</option>
            <option value="Covaxin">Covaxin</option>
            <option value="Sputnik V">Sputnik V</option>
            <option value="Pfizer-BioNTech">Pfizer-BioNTech</option>
            <option value="Moderna">Moderna</option>
          </select>
        </div>
      </section>

      {/* Insurance Information Section */}
      <div className={styles.formHeaderContainer}>
          <h3 className={styles.sectionHeading}>Insurance Information</h3>
          <hr className={styles.sectionLine} />
      </div>
      <section className={styles.formSection}>        
        <div className={styles.formGroup}>
          <div className={styles.inlineGroup}>
            <label htmlFor="insuranceProvider" className={styles.formLabel}>Insurance Provider</label>
            <select id="insuranceProvider" className={styles.formInput}>
              {/* Add provider options here */}
            </select>
          </div>
          <div className={styles.inlineGroup}>
            <label htmlFor="policyNumber" className={styles.formLabel}>Policy Number</label>
            <input type="text" id="policyNumber" placeholder="Policy Number" className={styles.formInput} />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inlineGroup}>
            <label htmlFor="coverageType" className={styles.formLabel}>Coverage Type</label>
            <select id="coverageType" className={styles.formInput}>
              <option>Full</option>
              <option>Partial</option>
              {/* More options */}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="policyExpiryDate" className={styles.formLabel}>Policy Expiry Date</label>
            <DatePicker className={styles.formInput} placeholderText="Select Expiry Date" />
          </div>          
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="policyHolderName" className={styles.formLabel}>Policy Holder Name</label>
            <input type="text" id="policyHolderName" placeholder="Policy Holder Name" className={styles.formInput} />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="policyHolderDOB" className={styles.formLabel}>Policy Holder Date of Birth</label>
            <DatePicker className={styles.formInput} placeholderText="Select Date" />
          </div>
      </section>

      {/* Submit, Search, and Clear Buttons */}
      <div className={styles.formActions}>
        <div className={styles.inlineButtonGroup}>
          <button type="submit" className={styles.formButton}>Submit</button>          
          <button type="button" className={styles.formButton}>Clear</button>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
