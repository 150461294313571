// Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FaHome, FaUserPlus, FaUserCheck, FaUserMd, FaFileInvoiceDollar, FaCalendarAlt, FaQuestionCircle } from 'react-icons/fa'; // Import icons from react-icons
import styles from './Sidebar.module.css'; // Import the CSS module

function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true); // State to manage collapse

  // Function to toggle collapse state
  const handleMouseEnter = () => setIsCollapsed(false);
  const handleMouseLeave = () => setIsCollapsed(true);

  return (
    <div
      className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`} // Apply collapsed class
      onMouseEnter={handleMouseEnter} // Expand on hover
      onMouseLeave={handleMouseLeave} // Collapse when not hovered
    >
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <Link to="/dashboard" className={styles.menuLink}>
            <FaHome className={styles.icon} /> {/* Dashboard Icon */}
            {!isCollapsed && <span className={styles.label}>Dashboard</span>} {/* Dashboard Label */}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/register" className={styles.menuLink}>
            <FaUserPlus className={styles.icon} /> {/* Registration Icon */}
            {!isCollapsed && <span className={styles.label}>Registration</span>} {/* Registration Label */}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/patientsearch" className={styles.menuLink}>
            <FaUserCheck className={styles.icon} />
            {!isCollapsed && <span className={styles.label}>Patient Search</span>}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/appointment" className={styles.menuLink}>
            <FaCalendarAlt className={styles.icon} />
            {!isCollapsed && <span className={styles.label}>Appointment</span>}
          </Link>
        </li>
         <li className={styles.menuItem}>
          <Link to="/consultant" className={styles.menuLink}>
            <FaUserMd className={styles.icon} /> {/* Consultant Icon */}
            {!isCollapsed && <span className={styles.label}>Consultation</span>} {/* Consultant Label */}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/billing" className={styles.menuLink}>
            <FaFileInvoiceDollar className={styles.icon} /> {/* Billing Icon */}
            {!isCollapsed && <span className={styles.label}>Billing</span>} {/* Billing Label */}
          </Link>
        </li>                       
      </ul>
      <ul className={styles.bottomMenu}>
        <li className={styles.menuItem}>
          <Link to="/help" className={styles.menuLink}>
            <FaQuestionCircle className={styles.icon} /> {/* Help Icon */}
            {!isCollapsed && <span className={styles.label}>Help & Support</span>} {/* Help & Support Label */}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
