import React, { useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css'; // Required styles for ReactDataGrid
import style from './Datagrid.module.css'; // Import custom CSS file for styling

const DataGrid = ({ heading, data }) => {
  const { columns, rows } = data; // Destructure columns and rows from data object
  
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10; // Set the number of rows per page

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedRows = rows.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  return (
    <div className={style.datagridContainer}>
      <h2>{heading}</h2>
      <ReactDataGrid
        columns={columns}
        rows={paginatedRows}
        rowHeight={30}
        headerRowHeight={40}
        className="customGrid" // Custom class for additional styles
      />
      {/* Pagination Controls */}
      <div className={style.paginationControls}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
          className={style.paginationButton}
        >
          Previous
        </button>
        <span className = {style.pageLabel}>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage >= totalPages - 1}
          className={style.paginationButton}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DataGrid;
