// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import LoginPage from './Pages/LoginPage'
import RegistrationPage from './Pages/RegistrationPage'; 
import DashboardPage from './Pages/DashboardPage'; 
import ConsultantPage from './Pages/ConsultantPage';
import BillingPage from './Pages/BillingPage';
import PatientSearchPage from './Pages/PatientSearchPage';  
import AppointmentPage from './Pages/AppointmentPage';
import UserProfilePage from './Pages/UserProfilePage'
import HelpPage from './Pages/HelpPage'


function App() {
  return (
    <Router>
      <Routes>        
        <Route path="/" element={<LoginPage />} />        
        <Route path="/register"  element={<RegistrationPage/>} />        
        <Route path="/dashboard"  element={<DashboardPage />}/>
        <Route path="/appointment"  element={<AppointmentPage />}/>
        <Route path="/consultant" element={<ConsultantPage />}/>
        <Route path="/billing" element={<BillingPage />}/>
        <Route path="/patientsearch" element={<PatientSearchPage />}/>
        <Route path="/userprofile" element={<UserProfilePage />}/>
        <Route path="help" element={<HelpPage />}/>
      </Routes>
    </Router>
  );
}

export default App;
