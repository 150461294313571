// App.js
import React from 'react';
import LoginForm from '../components/LoginForm'; 
import Layout from '../components/Layout';


function App() {
  return (
    <div>           
        <LoginForm />        
    </div>
  );
}

export default App;



