import React, { useContext, useRef } from 'react';
import styles from './PrescriptionPreview.module.css';
import { ConsultantContext } from '../context/ConsultantContext';
import { useState } from 'react';

const PrescriptionPreview = () => {
//   const {
//     selectedImage,
//     uploadMode,
//     prescriptionText,
//     setPrescriptionText,
//     handleSave,
//     handleClear,
//   } = useContext(ConsultantContext);
const [selectedImage, setSelectedImage] = React.useState(null);
const [uploadMode, setUploadMode] = useState(true)
const [prescriptionText, setPrescriptionText] = useState('');
const fileInputRef = useRef(null); // Reference to the hidden file input

  // Function to trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
const handleSave = () => {
    // Implement save logic here
  };
  const handleClear = () => {
    setSelectedImage(null);
    setPrescriptionText('');
  };
  
  return (
    <div className={styles.prescriptionPreview}>
      {/* Header */}
      <h2 className={styles.header}>Manage Prescription</h2>
      
      {/* Dynamic Content Area */}
      <div className={styles.contentArea}>        
        {uploadMode ? (
          <div className={styles.uploadContainer}>
            <label htmlFor="upload" className={styles.uploadLabel}>Upload Prescription Image</label>
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              className={styles.hiddenFileInput}
              style={{ display: 'none' }} // Hide the file input
            />
            {/* Upload button that triggers file input click */}
            <button className={styles.uploadButton} onClick={handleUploadClick}>
              Choose File
            </button>
          </div>
        ) : selectedImage ? (
          <div className={styles.imageContainer}>
            <img src="" alt="Prescription Preview" className={styles.previewImage} />
          </div>
        ) : (
          <div className={styles.noPreview}>Select a prescription to view or upload a new one.</div>
        )}
      </div>
      
      {/* Text Area for Writing Prescription */}
      <div className={styles.textAreaContainer}>
        <textarea
          className={styles.textArea}
          placeholder="Write prescription here..."
          value={prescriptionText}
          onChange={(e) => setPrescriptionText(e.target.value)}
        ></textarea>
      </div>
      
      {/* Buttons */}
      <div className={styles.buttonGroup}>
        <button className={styles.btn} onClick={handleSave}>Save</button>
        <button className={styles.btn} onClick={handleClear}>Clear</button>
      </div>
    </div>
  );
};

export default PrescriptionPreview;
