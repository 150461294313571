import React from 'react';
import styles from './ConsultantForm.module.css';
import prescriptionimg from '../assets/prescriptionpreview.png'
import { Checkbox, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

const ConsultantForm = () => {
  const datelist = ["06/01/2022", '21/03/2023', "05/09/2023", "05/02/2024", "16/05/2025", "01/08/2024"]
  return (
    <div className={styles.consultantForm}>
      {/* Main Heading */}
      <h1 className={styles.mainHeading}>Consultation Details</h1>
      
      {/* Demographic Details Section */}
      <div className={styles.section}>
        <h2 className={styles.subheading}>Demographic Details</h2>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label htmlFor="patientId">Patient ID</label>
            <input type="text" id="patientId" className={styles.textInput} />
          </div>
          <div className={styles.formGroup}>
            <label>Full Name:</label>
            <label>Suraj Kumar</label>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Date of Birth:</label>
            <label>03/06/1990</label>
          </div>
          <div className={styles.formGroup}>
            <label>Gender:</label>
            <label>Male</label>
          </div>
        </div>
      </div>

      {/* Health History Section */}
      <div className={styles.section}>
        <h2 className={styles.subheading}>Health History</h2>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label htmlFor="medicalConditions" className={styles.formLabel}>Medical Conditions</label>
            <textarea id="medicalConditions" placeholder="Existing Medical Conditions" className={styles.textArea}></textarea>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="allergies" className={styles.formLabel}>Allergies</label>
            <textarea id="allergies" placeholder="Known Allergies" className={styles.textArea}></textarea>            
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label htmlFor="currentMedications" className={styles.formLabel}>Current Medications</label>
            <textarea id="currentMedications" placeholder="Current Medications" className={styles.textArea}></textarea>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="pastSurgeries" className={styles.formLabel}>Past Surgeries</label>
            <textarea id="pastSurgeries" placeholder="Past Surgeries" className={styles.textArea}></textarea>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label htmlFor="familyHealthHistory" className={styles.formLabel}>Family Health History</label>
            <textarea id="familyHealthHistory" placeholder="Family Health History" className={styles.textArea}></textarea>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="healthGoals" className={styles.formLabel}>Health Goals</label>
            <textarea id="healthGoals" placeholder="Health Goals" className={styles.textArea}></textarea>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label htmlFor="otherHealthNotes" className={styles.formLabel}>Other Health Notes</label>
            <textarea id="otherHealthNotes" className={styles.textArea}></textarea>
          </div>
        </div>
      </div>

          {/* Recommendations Section */}
    <div className={styles.section}>
      <h2 className={styles.subheading}>Recommendations</h2>

    <label htmlFor="doctorDropdown" style={{ marginBottom: '10px' }}>Tests:</label>
    <div className = {styles.testGroup}>
      <div className = {styles.testList}>
        <div><Checkbox /> <label>Blood Test</label></div>
        <div><Checkbox /> <label>X-Ray</label></div>
        <div><Checkbox /> <label>MRI</label></div>
        <div><Checkbox /> <label>CT Scan</label></div>
      </div>
      <div className = {styles.testList}>
        <div><Checkbox /> <label>Ultrasound</label></div>
        <div><Checkbox /> <label>ECG</label></div>
        <div><Checkbox /> <label>Cholesterol Test</label></div>
      </div>
    </div>

      {/* Dropdown for Recommended Doctor */}
      <div className={styles.recommendationDropdown}>
        <label htmlFor="doctorDropdown" style={{ marginBottom: '10px' }}>Doctor:</label>
        <select id="doctorDropdown" className={styles.dropdown}>
          <option value="Dr. Arjun Verma">Dr. Arjun Verma (Cardiologist)</option>
          <option value="Dr. Kavita Sharma">Dr. Kavita Sharma (Pediatrician)</option>
          <option value="Dr. Rakesh Singh">Dr. Rakesh Singh (Neurologist)</option>
        </select>
      </div>
    </div>

      

      {/* Buttons */}
      <div className={styles.buttonGroup}>
        <button className={styles.btn}>New</button>
        <button className={styles.btn}>Search</button>
      </div>

      {/* Prescription Gallery Section */}
      <div className={styles.section}>
        <h2 className={styles.subheading}>Prescription History</h2>
        <div className={styles.gallery}>
          {/* Prescription Previews */}
          {Array.from({ length: 6 }).map((_, index) => (
            <div className={styles.galleryItem} key={index}>
              <div className={styles.visitDate}>Visit Data: {datelist[index]}</div>
              <img src={prescriptionimg} alt={`Prescription ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConsultantForm;
